<template>
	<!-- 审阅-测试用例-->
  <div class="container" style="padding: 20px;">
    <GModule>
			<div class="product">
				<a-tabs v-model:activeKey="activeKey">
					<a-tab-pane v-for="(i,index) in productArr" :key="index" :tab="i.title"></a-tab-pane>
				</a-tabs>
			</div>
			<div class="condition-box">
				<div style="display: flex;align-items: center;">
					<div class="state-box">
						<p>状态</p>
						<a-dropdown>
						    <template #overlay>
						        <a-menu selectable @click="handleMenuClick2">
									<a-menu-item v-for="(nitem,nindex) in nstatusArr" :key="nindex">{{nitem.title}}</a-menu-item>
						        </a-menu>
						    </template>
						    <a-button>
								{{nstatusIndex ? nstatusArr[nstatusIndex].title : '全部'}}
						        <DownOutlined />
						    </a-button>
						</a-dropdown>
					</div>
					<div class="state-box">
						<p>用例类型</p>
						<a-dropdown>
						    <template #overlay>
						        <a-menu selectable @click="handleTestCase">
									<a-menu-item v-for="(titem,tindex) in testArr" :key="tindex">{{titem.title}}</a-menu-item>
						        </a-menu>
						    </template>
						    <a-button>
								{{testIndex ? testArr[testIndex].title : '全部'}}
						        <DownOutlined />
						    </a-button>
						</a-dropdown>
					</div>
					<div class="state-box">
						<p>提审时间</p>
						<a-dropdown>
						    <template #overlay>
						        <a-menu selectable @click="handleManClick">
									<a-menu-item v-for="(mitem,mindex) in manArr" :key="mindex">{{mitem.title}}</a-menu-item>
						        </a-menu>
						    </template>
						    <a-button>
								{{manIndex ? manArr[manIndex].title : '全部'}}
						        <DownOutlined />
						    </a-button>
						</a-dropdown>
					</div>
				</div>
				<a-button class="btn clo1">批量通过</a-button>
			</div>
			<a-table 
				class="ttable" 
				sticky 
				:columns="columnsHe" 
				:data-source="data" 
				:scroll="{ x: 500,y:250 }" 
				bordered
				:pagination="false"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
			<template #bodyCell="{ column ,record}">
				<template v-if="column.title === '任务ID'">
				  	<div class="txt">
				  		<p class="red"></p>
				  		<p>002</p>
				  	</div>
				</template>
				<template v-if="column.title === '需求描述'">
				  	<a-popover placement="bottom">
				  	    <template #content>
				  	       <p style="max-width: 500px;">{{ record.name }}</p>
				  	    </template>
						 <p>{{ record.name }}</p>
				  	  </a-popover>
				</template>
				<template v-if="column.title === '需求图片'">
					<div style="max-width: 120px;overflow: hidden;">
						<a-image :preview="false" :width="120" src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" @click="openSwiper()"/>
					</div>
				</template>
			    <template v-if="column.key === 'operation'">
					<div class="caozuo">
						<p>通过</p>
						<p>驳回</p>
						<p>详情</p>
					</div>
				</template>
			  </template>
			</a-table>
			<div class="documentFt">
			  <a-pagination size="small" :total="50" />
			</div>
    </GModule>
  </div>
</template>
<script>
export default {
  name: 'ReviewTestCase',
  data(){
    return {
	    productArr:[
		    {title:"需求"},
		    {title:"测试用例"},
	    ], // 需求tab标签页
		activeKey:0,
		stateArr:[
			{title:"全部"},
		    {title:"待审核"},
		    {title:"审核中"},
		    {title:"未通过审核"},
		    {title:"已提项目"},
		    {title:"已完成"}
	    ] ,// 状态列表
		stateIndex:0,
		testArr:[
			{title:"全部"},
		    {title:"待审核"},
		    {title:"审核中"},
		    {title:"未通过审核"},
		    {title:"已提项目"},
		    {title:"已完成"}
		] ,// 用例类型
		testIndex:0,
		manArr:[
			{title:"全部"},
		    {title:"张三"},
		    {title:"李四"},
		    {title:"王五"},
		] ,// 状态列表
		manIndex:0,
		nstatusArr:[
			{title:"全部"},
		    {title:"未解决"},
		    {title:"已解决"},
		    {title:"已确认解决"},
			{title:"已反馈"}
		] ,// 创建时间列表
		nstatusIndex:0,
		columnsHe: [{
		    title: '用例ID',
		    width: 80,
		    dataIndex: 'index',
		    key: 'index',
		    fixed: 'left',
		  }, {
		    title: '用例类型',
		    width: 100,
		    dataIndex: 'name',
		    key: 'describe',
		    fixed: 'left',
		  }, {
		    title: '用例摘要',
		    width: 120,
		    dataIndex: 'image',
		    key: 'image'
		  }, {
		    title: '前置条件',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '操作步骤',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '预期结果',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '提审时间',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '状态',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  },{
		    title: '操作',
		    key: 'operation',
		    fixed: 'right',
		    width: 180,
		}],
		data: [{
		  key: '1',
		  name: 'John Brown',
		  age: 32,
		  address: 'New York No. 1 Lake Park',
		}, {
		  key: '2',
		  name: 'Jim Green大家聚聚军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军',
		  age: 42,
		  address: 'London No. 1 Lake Park',
		}, {
		  key: '3',
		  name: 'Joe Black',
		  age: 32,
		  images:[],
		  address: 'Sidney No. 1 Lake Park',
		}, {
		  key: '4',
		  name: 'Jim Red',
		  age: 32,
		  address: 'London No. 2 Lake Park',
		},{
		  key: '5',
		  name: 'John Brown',
		  age: 32,
		  address: 'New York No. 1 Lake Park',
		}, {
		  key: '6',
		  name: 'Jim Green',
		  age: 42,
		  address: 'London No. 1 Lake Park',
		}, {
		  key: '7',
		  name: 'Joe Black',
		  age: 32,
		  address: 'Sidney No. 1 Lake Park',
		}, {
		  key: '8',
		  name: 'Jim Red',
		  age: 32,
		  address: 'London No. 2 Lake Park',
		}],
    }
  },
  methods:{
	  handleManClick(e){
	  		this.manIndex = e.key
	  		console.log("选择",this.manIndex)
	  },
	  handleMenuClick2(e){
	  	  this.nstatusIndex = e.key
	  	  console.log("选择",this.nstatusIndex)
	  },
	  handleTestCase(e){
	  	  this.testIndex = e.key
	  	  console.log("选择",this.testIndex)
	  },
	  openSwiper(){
		  // 弹出图片列表
		  this.$store.commit('setSwiper',1)
	  }
  }

}
</script>

<style scoped lang="scss">
.time-box{
	display: flex;
	align-items: center;
	p{
		&:nth-child(2){
			padding-left: 30px;
		}
		&:nth-child(3){
			width: 80px;
			font-size: 12px;
			color: #999999;
			border: 1px solid #ddd;
			border-radius: 5px;
			padding: 5px 10px;
		}
	}
}	
.condition-box{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 5px 0 10px 0;
	.state-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		p{
			margin: 0 10px 0 20px;
		}
	}
	.btn{
		margin-left: 20px;
		margin-bottom: 5px;
	}
}
.ttable{
	margin-top:0;	
	.txt{
		display: flex;
		align-items: center;
		.red{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: red;
			margin-right: 5px;
		}
	}
}
.documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.caozuo{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
		margin-left: 5px;
	}
}


</style>